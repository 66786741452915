// istanbul ignore next
import { ButtonSelect } from '@kandji-inc/nectar-ui';
import { i18n } from 'src/i18n';
import { constants } from '../../../common';
import type { DetectionTypeSelectorState } from '../../../threat.types';

const {
  THREAT_DETECTION_TYPE: { FILE, BEHAVIORAL },
} = constants;

type DetectionTypeSelectorOption = {
  key?: DetectionTypeSelectorState;
  value: DetectionTypeSelectorState;
  label: string;
  checked?: boolean;
};

type DetectionTypeSelectorProps = {
  value: DetectionTypeSelectorState | undefined;
  onChange: (value: DetectionTypeSelectorState | undefined) => void;
};

const DetectionTypeSelector = (props: DetectionTypeSelectorProps) => {
  const { value, onChange } = props;

  const filterValue: string = value || FILE;
  const filterOptions: DetectionTypeSelectorOption[] = [
    {
      label: i18n.t('File detections'),
      value: FILE,
    },
    {
      label: i18n.t('Behavioral detections'),
      value: BEHAVIORAL,
    },
  ];

  return (
    <ButtonSelect.Root
      type="single"
      value={filterValue}
      data-testid="detection-type-filter"
      compact
    >
      {filterOptions.map((item) => (
        <ButtonSelect.Item
          key={item.value}
          value={item.value}
          onClick={() => {
            onChange(item.value);
          }}
        >
          {item.label}
        </ButtonSelect.Item>
      ))}
    </ButtonSelect.Root>
  );
};

export default DetectionTypeSelector;
