import { Button, Flex } from '@kandji-inc/nectar-ui';
import type { ReactNode } from 'react';
import type { OnApply, OnClear } from 'src/features/edr/common/common.types';
import ThreatListExportButton from '../common/components/ThreatListExportButton';
import type { OnClearAll } from '../threat.types';

type ThreatListFiltersProps = {
  onApply: OnApply;
  onClear: OnClear;
  onClearAll: OnClearAll;
  isFiltering: boolean;
  isExportHidden: boolean;
  onExport: () => void;
  children: ReactNode;
  isExportDisabled?: boolean;
};

const ThreatListFilters = (props: ThreatListFiltersProps) => {
  const {
    onClearAll,
    isFiltering,
    isExportHidden,
    onExport,
    children,
    isExportDisabled = false,
  } = props;

  return (
    <Flex flow="row" gap="md" justifyContent="space-between" py3 px5>
      <Flex flow="row" gap="md">
        {children}
        {isFiltering && (
          <Button variant="subtle" compact onClick={onClearAll}>
            Clear all
          </Button>
        )}
      </Flex>
      {!isExportHidden && (
        <ThreatListExportButton
          onClick={onExport}
          disabled={isExportDisabled}
        />
      )}
    </Flex>
  );
};

export default ThreatListFilters;
