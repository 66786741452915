import { Flex, Heading, Toast, styled } from '@kandji-inc/nectar-ui';
import type { Computer } from '@shared/types/common.types';
import { InterfaceContext } from 'contexts/interface';
import useWhiteBg from 'features/util/hooks/use-white-bg';
import { i18n } from 'i18n';
import { useContext, useRef } from 'react';
import featureFlags from 'src/config/feature-flags';
import useAccount from 'src/contexts/account';
import ThreatListUnassignedEmptyState from '../common/components/ThreatListUnassignedEmptyState';
import useThreatsToast from '../common/hooks/use-threats-toast';
import ThreatListEvents from './ThreatListEvents';

const Container = styled(Flex, {
  flexDirection: 'column',
  minHeight: '100vh',
});

type ThreatListProps = Readonly<{
  computer?: Computer | undefined;
  isOnDeviceRecord?: boolean;
}>;

const ThreatList = (props: ThreatListProps) => {
  const { computer, isOnDeviceRecord } = props;
  useWhiteBg(!isOnDeviceRecord);
  const { currentCompany }: any = useAccount();
  const { sidebarOpened } = useContext(InterfaceContext);
  const toastRef = useRef(null);
  const { showToast } = useThreatsToast(toastRef);

  const isVulnFeatureFlagOn = featureFlags.getFlag('vm_06252024_vuln-poc');
  const isVulnSKUEnabled =
    currentCompany?.feature_configuration?.vulnerability_management?.enabled;
  const isVulnEnabled = isVulnFeatureFlagOn && isVulnSKUEnabled;

  return (
    <>
      <Container>
        {!isOnDeviceRecord && !isVulnEnabled && (
          <Heading size="1" css={{ padding: '$6 $5 $2' }}>
            {i18n.t('Threats')}
          </Heading>
        )}
        {!isOnDeviceRecord && <ThreatListUnassignedEmptyState />}
        <ThreatListEvents
          deviceId={computer?.id}
          showToast={showToast}
          isOnDeviceRecord={isOnDeviceRecord}
        />
      </Container>
      <Toast
        testId="threat-toaster"
        managerRef={toastRef}
        viewportCss={{
          left: sidebarOpened ? '256px' : '78px',
        }}
      />
    </>
  );
};

export default ThreatList;
