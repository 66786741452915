// istanbul ignore file
import { Flex, styled } from '@kandji-inc/nectar-ui';
import { AccountContext } from 'contexts/account';
import { useContext, useMemo, useState } from 'react';
import featureFlags from 'src/config/feature-flags';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import ThreatListErrorBoundary from '../common/components/ThreatListErrorBoundary';
import ThreatListReleaseModal from '../common/components/ThreatListReleaseModal';
import ThreatListSuspense from '../common/components/ThreatListSuspense';
import ThreatListTable from '../common/components/ThreatListTable/ThreatListTable';
import constants from '../common/constants';
import useFileStatusPolling from '../common/hooks/use-file-status-polling';
import useGetBehavioralThreats from '../common/hooks/use-get-behavioral-threats/use-get-behavioral-threats';
import toRequestFormat from '../common/hooks/use-get-threats/toRequestFormat';
import useGetThreats from '../common/hooks/use-get-threats/use-get-threats';
import useStateParams from '../common/hooks/use-state-params';
import downloadCSVFile from '../common/utils/downloadCSVFile';
import getDateRange from '../common/utils/getDateRange';
import getDisplayDetectionPeriod from '../common/utils/getDisplayDetectionPeriod';
import { ThreatService } from '../data-service';
import type {
  BehavioralThreatDetail,
  DetectionTypeSelectorState,
  FileStatus,
  FilterFields,
  FiltersState,
  PageSize,
  PaginationState,
  ShowToast,
  SortColumnDirection,
  SortColumnName,
  SortColumnState,
  TableHeaderColumn,
  ThreatClassification,
  ThreatDetail,
  ThreatStatus,
} from '../threat.types';
import ThreatListFilters from './ThreatListFilters';
import ThreatListFiltersPM from './ThreatListFiltersPM';
import ThreatListSplitView from './ThreatListSplitView/ThreatListSplitView';
import ThreatListTableBody from './ThreatListTableBody';
import ThreatListTiles from './ThreatListTiles/ThreatListTiles';
import useGetStatusCounts from './hooks/use-get-status-counts';
import {
  defaultFilters,
  filtersParamConfig,
  paginationParamConfig,
  sortParamConfig,
} from './params/params';

const Container = styled(Flex, {
  position: 'sticky',
  backgroundColor: '$neutral0',
  zIndex: 1,
});

const errorBoundaryTableCss = {
  flex: 1,
  minHeight: 400,
};

const errorBoundaryTilesCss = {
  minHeight: 172,
  border: '1px solid $neutral20',
  borderRadius: '$2',
  marginTop: '$4',
  marginBottom: '$4',
};

const getColumns = (
  isOnDeviceRecord: boolean,
  isSplitViewEnabled: boolean,
  isFileDetectionType: boolean,
): TableHeaderColumn[] => [
  {
    name: 'threat_name',
    title: 'Threat Name',
    size: 11,
    isHidden: isSplitViewEnabled,
  },
  {
    name: 'threat_id',
    title: 'Threat ID',
    tooltip: 'SHA-256',
    size: isFileDetectionType ? 13 : 12,
    isHidden: !isSplitViewEnabled,
  },
  {
    name: isFileDetectionType ? 'process_name' : 'process',
    title: 'Process',
    size: isFileDetectionType ? 10 : 11,
  },
  {
    name: 'classification',
    title: 'Classification',
    size: 10,
  },
  {
    name: 'device_name',
    title: 'Device',
    size: 11,
    isHidden: isOnDeviceRecord,
  },
  {
    name: 'detection_date',
    title: 'Detection Date',
    size: 11,
  },
  {
    name: 'status',
    title: 'Status',
    size: 11,
  },
];

type ThreatListEventsProps = Readonly<{
  deviceId?: string | undefined;
  showToast: ShowToast;
  showChartTiles?: boolean | undefined;
  isTableScrollable?: boolean | undefined;
  isOnDeviceRecord?: boolean | undefined;
}>;

const ThreatListEvents = (props: ThreatListEventsProps) => {
  const { deviceId, isOnDeviceRecord, showToast } = props;
  const { userSettings } = useContext(AccountContext);
  const threatService = useMemo(() => new ThreatService(), []);
  const [threatToRelease, setSelectedThreatForRelease] = useState<
    ThreatDetail | undefined
  >();
  const [selectedForSplitView, setSelectedForSplitView] = useState<
    ThreatDetail | undefined
  >();

  const isProcessMonitoringFFOn = featureFlags.getFlag(
    'edr_091324_process-monitoring-ui',
  );

  const isSplitViewEnabled = isProcessMonitoringFFOn && isOnDeviceRecord;

  const {
    filters,
    setFilters,
    pagination,
    setPagination,
    sort,
    setSort,
    isDefaultFilterState,
  } = useStateParams<FiltersState, PaginationState, SortColumnState>(
    filtersParamConfig,
    paginationParamConfig,
    sortParamConfig,
    defaultFilters,
  );

  const isFileDetectionType = Boolean(
    filters.detectionType === constants.THREAT_DETECTION_TYPE.FILE ||
      filters.detectionType === undefined,
  );

  const {
    data: dataFileThreats,
    isLoading: isLoadingFileThreats,
    isError: isErrorFetchingFileThreats,
    error: errorFetchingFileThreats,
    refetch: refetchThreats,
  } = useGetThreats(filters, sort, pagination, deviceId, isFileDetectionType);

  const {
    data: dataBehavioralThreats,
    isLoading: isLoadingBehavioralThreats,
    isError: isErrorFetchingBehavioralThreats,
    error: errorFetchingBehavioralThreats,
  } = useGetBehavioralThreats(
    filters,
    sort,
    pagination,
    deviceId,
    !isFileDetectionType,
  );

  // TODO: Move this logic away from the main component
  const dataBehavioralThreatsParsed = {
    results: dataBehavioralThreats?.detections.map((item) => ({
      ...item,
      file_hash: item.threat_id,
      process_name: item.process,
      status: item.threat_status,
    })),
    malware_count: undefined,
    pup_count: undefined,
    ...dataBehavioralThreats,
  };

  const dataThreats = isFileDetectionType
    ? dataFileThreats
    : dataBehavioralThreatsParsed;
  const isLoadingThreats = isFileDetectionType
    ? isLoadingFileThreats
    : isLoadingBehavioralThreats;
  const isErrorFetchingThreats = isFileDetectionType
    ? isErrorFetchingFileThreats
    : isErrorFetchingBehavioralThreats;
  const errorFetchingThreats = isFileDetectionType
    ? errorFetchingFileThreats
    : errorFetchingBehavioralThreats;

  const {
    data: dataStatusCounts,
    isLoading: isLoadingStatusCounts,
    isError: isErrorFetchingStatusCounts,
    refetch: refetchStatusCounts,
  } = useGetStatusCounts(
    getDateRange(filters.detectionDate),
    !isOnDeviceRecord,
  );

  const dataCount = dataThreats?.count;
  const malwareCount = dataThreats?.malware_count;
  const pupCount = dataThreats?.pup_count;
  const threats: ThreatDetail[] | BehavioralThreatDetail[] =
    dataThreats?.results || [];
  const isEmpty = dataCount === 0;
  const isFiltering = !isDefaultFilterState();
  const isFilteringOmittingFields = !isDefaultFilterState([
    'detectionDate',
    'detectionType',
  ]);
  const displayDetectionPeriod: string = getDisplayDetectionPeriod(
    filters.detectionDate,
    userSettings?.timezone,
  );

  const handlePaginationChange = (page: number, pageSize: PageSize) => {
    setPagination({ page, pageSize });
    setSelectedForSplitView(undefined);
  };

  const handleExportThreatDetailsClick = async () => {
    const { limit, offset, ...rest } = toRequestFormat(
      filters,
      sort,
      pagination,
      deviceId,
    );

    showToast(`export-preparing`);
    try {
      const rawData = await threatService.getExportThreats(rest);
      downloadCSVFile(rawData?.data);
      showToast(`export-complete`);
    } catch {
      showToast(`export-error`);
    }
  };

  const setFiltersAndResetPage = (newFilters: FiltersState) => {
    setFilters(newFilters);
    setPagination({ page: 1 });
    setSelectedForSplitView(undefined);
  };

  const handleClearFilters = (filterName: FilterFields) => {
    setFiltersAndResetPage({ [filterName]: undefined });
  };

  const handleApplyFilters = (
    filterName: FilterFields,
    value: ThreatStatus[] | ThreatClassification | DetectionDateFilterFields,
  ) => {
    setFiltersAndResetPage({ [filterName]: value });
  };

  const handleApplyFiltersForDetectionType = (
    value: DetectionTypeSelectorState | undefined,
  ) => {
    setFiltersAndResetPage({
      status: undefined,
      classification: undefined,
      detectionDate: undefined,
      detectionType: value,
      query: undefined,
    });
  };

  const handleClearAllFilters = () => {
    setFiltersAndResetPage({
      status: undefined,
      classification: undefined,
      detectionDate: undefined,
      query: undefined,
    });
  };

  const handleStatusEventChange = (event: FileStatus) => {
    showToast(`status-${event}`);
    if (event === 'resolved') {
      refetchThreats();
      refetchStatusCounts();
    }
  };

  const { startFileStatusCheck, isPolling: isThreatBeingChecked } =
    useFileStatusPolling(handleStatusEventChange);

  const handleRecheckThreatStatus = (selectedThreat: ThreatDetail) => {
    startFileStatusCheck(
      selectedThreat.device_id,
      selectedThreat.file_hash,
      selectedThreat.file_path,
    );
  };

  const handleColumnSortChange = (
    sortBy: SortColumnName | undefined,
    sortOrder: SortColumnDirection | undefined,
  ) => {
    setSort({ sortBy, sortOrder });
    setSelectedForSplitView(undefined);
  };

  const handleRelease = (deviceCount: number) => {
    handleCloseModal();
    showToast('release-complete', {
      content: `${threatToRelease?.threat_name} will be released on ${deviceCount} devices upon next check in.`,
    });
  };

  const handleReleaseError = (type: string) => {
    handleCloseModal();
    showToast(type);
  };

  const handleCloseModal = () => {
    setSelectedThreatForRelease(undefined);
  };

  const handleGetReleaseDetails = (item: ThreatDetail) => {
    setSelectedThreatForRelease(item);
  };

  const handleOpenSplitView = (threat: ThreatDetail) => {
    setSelectedForSplitView(threat);
  };

  const handleCloseSplitView = () => {
    setSelectedForSplitView(undefined);
  };

  const containerCssProcessMonitoring = {
    '& td:first-child': {
      paddingLeft: '$5',
    },
    '& td:last-child': {
      textAlign: selectedForSplitView ? 'left' : 'right',
    },
  };

  const containerCss = {
    '& tr > td:first-child': {
      padding: 0,
    },
    '& tr > td:first-child > div': {
      justifyContent: 'start',
      paddingLeft: 10,
    },
  };

  let ThreatListFiltersPlaceholder;
  if (isSplitViewEnabled) {
    ThreatListFiltersPlaceholder = ThreatListFiltersPM;
  } else {
    ThreatListFiltersPlaceholder = ThreatListFilters;
  }

  return (
    <>
      {!isOnDeviceRecord && (
        <ThreatListErrorBoundary
          css={errorBoundaryTilesCss}
          preReset={refetchStatusCounts}
        >
          <ThreatListTiles
            data={dataStatusCounts}
            isLoading={isLoadingStatusCounts}
            isError={isErrorFetchingStatusCounts}
            dateValue={filters?.detectionDate?.value}
            displayDetectionPeriod={displayDetectionPeriod}
          />
        </ThreatListErrorBoundary>
      )}
      <ThreatListErrorBoundary
        css={errorBoundaryTableCss}
        preReset={refetchThreats}
      >
        <Flex flow="row" flex={1} css={{ mx: isOnDeviceRecord ? '-$5' : 0 }}>
          <Flex flow="column">
            <Container
              flow="row"
              gap="sm"
              alignItems="end"
              justifyContent="space-between"
              py3
              px5
              css={{
                top: isOnDeviceRecord ? 135 : 0,
              }}
            >
              <ThreatListFiltersPlaceholder
                filters={filters}
                onApply={handleApplyFilters}
                onDetectionTypeApply={handleApplyFiltersForDetectionType}
                onClear={handleClearFilters}
                onClearAll={handleClearAllFilters}
                isFiltering={isFiltering}
                malwareCount={malwareCount}
                pupCount={pupCount}
                isEmpty={isEmpty}
                isLoading={isLoadingThreats}
                onExport={handleExportThreatDetailsClick}
                isFileDetectionType={isFileDetectionType}
              />
            </Container>
            <ThreatListTable
              isLoading={isLoadingThreats}
              isError={isErrorFetchingThreats}
              error={errorFetchingThreats}
              sort={sort}
              onColumnSort={handleColumnSortChange}
              pagination={pagination}
              onPaginationChange={handlePaginationChange}
              dataCount={dataCount}
              displayDetectionPeriod={displayDetectionPeriod}
              isFiltering={isFilteringOmittingFields}
              showToast={showToast}
              columns={getColumns(
                Boolean(isOnDeviceRecord),
                isSplitViewEnabled,
                isFileDetectionType,
              )}
              isMoreActionsShown={!selectedForSplitView}
              isFirstHeaderColumnShown={!isSplitViewEnabled}
              containerCss={{
                '& tr > td:last-child': {
                  paddingRight: '$5',
                },
                '& th:last-child, td:last-child': {
                  padding: 0,
                  textAlign: 'right',
                },
                '& thead': {
                  top: isOnDeviceRecord ? (isSplitViewEnabled ? 227 : 188) : 52,
                },
                '& th:first-child': {
                  paddingLeft: '$5',
                },
                ...(isSplitViewEnabled
                  ? containerCssProcessMonitoring
                  : containerCss),
              }}
              paginationCss={{
                position: 'sticky',
                width: 'auto',
                '.full-width-sidebar-closed &': {
                  width: 'auto',
                },
                bottom: 0,
              }}
            >
              <ThreatListTableBody
                threats={threats}
                query={filters.query}
                onGetReleaseDetails={handleGetReleaseDetails}
                timezone={userSettings?.timezone}
                isOnDeviceRecord={isOnDeviceRecord}
                onOpenSplitView={handleOpenSplitView}
                isMoreActionsShown={!selectedForSplitView}
                onRecheckStatus={handleRecheckThreatStatus}
                isThreatBeingChecked={isThreatBeingChecked}
                isSplitViewEnabled={isSplitViewEnabled}
                selectedThreatForSplitView={selectedForSplitView}
                isFileDetectionType={isFileDetectionType}
              />
            </ThreatListTable>
          </Flex>
          {selectedForSplitView && (
            <Flex
              css={{
                height: isOnDeviceRecord ? 'calc(100vh - 135px)' : '100vh',
                zIndex: 1,
                position: 'sticky',
                top: isOnDeviceRecord ? 135 : 0,
              }}
            >
              <ThreatListSuspense>
                <ThreatListSplitView
                  selected={selectedForSplitView}
                  onClose={handleCloseSplitView}
                  query={filters.query}
                  timezone={userSettings?.timezone}
                  onReleaseThreat={() =>
                    handleGetReleaseDetails(selectedForSplitView)
                  }
                  onRecheckStatus={handleRecheckThreatStatus}
                  isThreatBeingChecked={isThreatBeingChecked}
                />
              </ThreatListSuspense>
            </Flex>
          )}
        </Flex>
        {threatToRelease && (
          <ThreatListReleaseModal
            name={threatToRelease?.threat_name}
            fileHash={threatToRelease?.file_hash}
            deviceId={threatToRelease?.device_id}
            libraryItemId={threatToRelease?.library_item_id}
            onClose={handleCloseModal}
            onRelease={handleRelease}
            onError={handleReleaseError}
          />
        )}
      </ThreatListErrorBoundary>
    </>
  );
};

export default ThreatListEvents;
