import { Button, Flex, type IconNames, styled } from '@kandji-inc/nectar-ui';
import * as Popover from '@radix-ui/react-popover';
import { useEffect, useState } from 'react';
import EDRTooltip from 'src/features/edr/common/components/EDRTooltip';

const TinyButton = styled(Button, {
  '&:not(empty)': {
    svg: { width: '$3', height: '$3' },
    padding: 1,
  },
});

const PopoverContent = styled(Popover.Content, {
  marginTop: 1,
  height: 'min-content',
  background: '$neutral0',
  filter: 'drop-shadow($elevation2)',
  borderRadius: '$rounded',
  zIndex: 2,
  paddingInline: '$4',
  paddingBlock: '$3',
  cursor: 'unset',
});

const ThreatListPopover = (props: {
  children: React.ReactNode;
  trigger: React.ReactNode;
  isOpen: boolean;
  onInteractOutside?: () => void;
}) => {
  const { children, trigger, isOpen, onInteractOutside } = props;

  return (
    <Popover.Root open={isOpen}>
      <Popover.Anchor>{trigger}</Popover.Anchor>
      <PopoverContent
        onInteractOutside={
          // istanbul ignore next
          typeof onInteractOutside === 'function'
            ? onInteractOutside
            : // istanbul ignore next
              () => null
        }
      >
        {children}
        <Popover.Arrow
          width={12}
          height={8}
          style={{ fill: 'var(--colors-neutral0)' }}
        />
      </PopoverContent>
    </Popover.Root>
  );
};

const ThreatListButtonPopover = (props: {
  children: React.ReactNode;
  icon: IconNames;
  tooltip: string;
  onOpen?: () => void;
  testId?: string;
}) => {
  const {
    children,
    icon = 'clock-rotate-left',
    onOpen,
    tooltip,
    testId,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof onOpen === 'function' && isOpen) {
      onOpen();
    }
  }, [onOpen, isOpen]);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleInteractOutside = () => {
    setIsOpen(false);
  };

  return (
    <ThreatListPopover
      isOpen={isOpen}
      onInteractOutside={handleInteractOutside}
      trigger={
        <EDRTooltip side="bottom" label={tooltip}>
          <TinyButton
            variant="subtle"
            icon={{ name: icon }}
            onClick={handleButtonClick}
            data-testid={testId}
          />
        </EDRTooltip>
      }
    >
      <Flex flow="column" gap="xs">
        {children}
      </Flex>
    </ThreatListPopover>
  );
};

export default ThreatListButtonPopover;
