import getDateRange from '../../../common/utils/getDateRange';
import getSortBy from '../../../common/utils/getSortBy';
import type {
  FiltersState,
  GetBehavioralThreatDetailsRequestParams,
  PaginationState,
  SortColumnState,
  ThreatClassification,
} from '../../../threat.types';

const toRequestFormat = (
  filters: FiltersState,
  sort: SortColumnState,
  pagination: PaginationState,
  deviceId?: string,
): GetBehavioralThreatDetailsRequestParams => {
  const limit = String(pagination.pageSize);
  const offset = String(((pagination.page || 1) - 1) * Number(limit));
  const classifications =
    filters.classification?.toLowerCase() as ThreatClassification;
  const statuses = (filters.status || undefined)
    ?.map((item) => item.toLowerCase())
    .join(',');
  const newDeviceId = deviceId || '';
  const sortBy = getSortBy(sort);
  const { from, to } = getDateRange(filters.detectionDate);
  const term = filters.query || '';

  const filtersTransformedToApi: GetBehavioralThreatDetailsRequestParams = {
    limit,
    offset,
    sort_by: sortBy,
    device_id: newDeviceId,
    statuses,
    classifications,
    term,
    detection_date_from: from,
    detection_date_to: to,
  };

  return filtersTransformedToApi;
};

export default toRequestFormat;
