/* istanbul ignore file */
import { t } from '@kandji-inc/nectar-i18n';

export const common = {
  error: () => t('Something went wrong'),
  durationHours: (hours: number) =>
    t('{hours, plural, one {# hour} other {# hours}}', {
      hours,
      _context: 'length of time, in hours',
    }),
  durationHrs: (hours: number) =>
    t('{hours, plural, one {# hr} other {# hrs}}', {
      hours,
      _context: 'length of time, in hours, shortened notation',
    }),
  durationMinutes: (minutes: number) =>
    t('{minutes, plural, one {# minute} other {# minutes}}', {
      minutes,
      _context: 'length of time, in minutes',
    }),
  durationMins: (minutes: number) =>
    t('{minutes, plural, one {# min} other {# mins}}', {
      minutes,
      _context: 'length of time, in minutes, shortened notation',
    }),
  durationSeconds: (seconds: number) =>
    t('{seconds, plural, one {# second} other {# seconds}}', {
      seconds,
      _context: 'length of time, in seconds',
    }),
  durationSecs: (seconds: number) =>
    t('{seconds, plural, one {# sec} other {# secs}}', {
      seconds,
      _context: 'length of time, in seconds, shortened notation',
    }),
  durationDays: (days: number) =>
    t('{days, plural, one {# day} other {# days}}', {
      days,
      _context: 'length of time, in days, shortened notation',
    }),
  durationMonths: (months: number) =>
    t('{months, plural, one {# month} other {# months}}', { months }),
  durationYears: (years: number) =>
    t('{years, plural, one {# year} other {# years}}', { years }),
  numItems: (count: number) =>
    t('{count, plural, one {# item} other {# items}}', { count }),
  numDevices: (count: number) =>
    t('{count, plural, one {# device} other {# devices}}', { count }),
  numMacs: (count: number) =>
    t('{count, plural, one {# Mac} other {# Macs}}', { count }),
  numIPhones: (count: number) =>
    t('{count, plural, one {# iPhone} other {# iPhones}}', { count }),
  numIPads: (count: number) =>
    t('{count, plural, one {# iPad} other {# iPads}}', { count }),
  numIPods: (count: number) =>
    t('{count, plural, one {# iPod} other {# iPods}}', { count }),
  numAppleTVs: (count: number) =>
    t('{count, plural, one {# Apple TV} other {# Apple TVs}}', { count }),
};
